import http from 'vue-http-rexsheng'
const Mock = require('mockjs')
var Random = Mock.Random;
http.ajax.addMock("@post:/api/user/resume", function (d) {
    var data = Mock.mock({
        "code": 1001,
        "message": "操作成功",
        "data": {
            "photo": [
                "/upload/image/avatar/20191202\0f5194379dfef538c96c5635a982b9b5.png"
            ],
            "good_comment": 2,
            "mediocrity_comment": 0,
            "bad_comment": 0
        }
    })
    return data
})
http.ajax.addMock("@post:/api/user/login", function (d) {
    var data = Mock.mock({
        "code": 1001,
        "message": "操作成功",
        "data": {
            "aunt_id": 1,
            "token": "821933335f9b7cc7a17d3ee081371090"
        }
    })
    return data
})
http.ajax.addMock("@post:/api/user/resume_info", function (d) {
    var data = Mock.mock({
        "code": 1001,
        "message": "操作成功",
        "data": {
            "aunt": {
                "credit": "0.0",
                "name": "但是",
                "age": 0,
                "photo": "武器大全为",
                "education": "的权威地位",
                "nation": "但是",
                "address": 370200,
                "working_years": 10,
                "start": "0.0",
                "check_id_card": "0",
                "check_background": 0,
                "health": "1",
                "skill": "0",
                "estimate": "5",
                "show_photo": ["ddddd",'2222'],
                "comment_count": 0
            },
            "comment": [{
                    "start": 4,
                    "comment": "6666",
                    "photo": [
                        ""
                    ],
                    "create_time": 1576224692,
                    "icon": "/upload/image/avatar/20191217502fdb2bf227d09d6a02701b324b27d6.gif",
                    "nick_name": "cool_yellow",
                    "serve_name": "全天月嫂"
                },
                {
                    "start": 0,
                    "comment": "好极了",
                    "photo": [
                        ""
                    ],
                    "create_time": 1576224692,
                    "icon": "/upload/image/avatar/20191217502fdb2bf227d09d6a02701b324b27d6.gif",
                    "nick_name": "cool_yellow",
                    "serve_name": "全天月嫂"
                },
                {
                    "start": 0,
                    "comment": "非常棒",
                    "photo": [
                        ""
                    ],
                    "create_time": 1576224692,
                    "icon": "/upload/image/avatar/20191217502fdb2bf227d09d6a02701b324b27d6.gif",
                    "nick_name": "cool_yellow",
                    "serve_name": "全天月嫂"
                }
            ],
            "experience": [{
                    "undergo": "清华大学"
                },
                {
                    "undergo": "北京大学"
                }
            ]
        }
    })
    return data
})