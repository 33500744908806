<template>
    <transition name="msg">
        <div class="message-bg" v-show="show">
            <div class="message-main">
                <div class="title">
                    {{title}}</div>
                <div class="content" v-html="content"></div>
                <div class="btn-group">
                    <button class="btn btn-default" @click="cancleHandle" v-show="isShowCancelBtn">{{ cancelBtnText }}</button>
                    <button class="btn btn-primary" @click="confirmHandle">{{ confirmBtnText }}</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            title: "",
            content: "",
            isShowCancelBtn: true,
            cancelBtnText: "取消",
            confirmBtnText: "确定"
        };
    },
    methods: {
        cancleHandle() {
            if (this.cancel) {
                this.cancel();
                this.$msgBox.hide();
            } else this.$msgBox.hide();
        },
        confirmHandle() {
            if (this.confirm) {
                this.confirm();
                this.$msgBox.hide();
            } else this.$msgBox.hide();
        }
    }
};
</script>

<style scoped>
.msg-enter-active,
.msg-leave-active {
    transition: opacity 0.5s;
}
.msg-enter,
.msg-leave-to {
    opacity: 0;
}
.message-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow-x: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
}
.message-main {
    background-color: #fff;
    width: 580px;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    margin: 450px auto;
    border-radius: 20px;
    padding: 32px;
}
.message-main .title {
    line-height: 40px;
    font-size: 32px;
    color: #353535;
    text-align: center;
}
.message-main .title span {
    float: right;
    margin-right: -15px;
    padding: 0 15px;
    font-size: 22px;
    cursor: pointer;
}
.message-main .content {
    margin: 20px;
    font-size: 30px;
    color: #353535;
    line-height: 50px;
    text-align: center;
}
.message-main .btn-group {
    height: 80px;
    text-align: center;
}
.message-main .btn-group button,
.message-main .btn-group a {
    background: #D8D8D8;
    border-radius: 12px;
    color: #fff;
    height: 80px;
    width: 240px;
    border: none;
    display: inline-block;
    font-size: 30px;
}
.message-main .btn-group button:last-child,
.message-main .btn-group a:last-child {
    background: #D51F13;
    margin-left: 30px;
}
</style>

