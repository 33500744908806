import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [{
    path: '*',
    component: resolve => require(['@/views/404.vue'], resolve),
    meta: {
        title: "404 错误页面",
        auth: false
    }
}, {
    path: '/course/login',
    name: '免费领取',
    component: resolve => require(['@/views/Course/login.vue'], resolve),
    meta: {
        title: "免费领取",
        auth: false
    }
}, {
    path: '/app/index',
    name: '首页',
    component: resolve => require(['@/views/App/index'], resolve),
    meta: {
        title: "首页",
        auth: false
    }
},{
    path: '/app/about',
    name: '关于我们',
    component: resolve => require(['@/views/App/about.vue'], resolve),
    meta: {
        title: "关于我们",
        auth: false
    }
},{
    path: '/app/agreement',
    name: '隐私政策',
    component: resolve => require(['@/views/App/agreement.vue'], resolve),
    meta: {
        title: "隐私政策",
        auth: false
    }
},{
    path: '/course/find/list',
    component: resolve => require(['@/views/Course/Find/list.vue'], resolve),
    meta: {
        title: "在线学习-发现",
        auth: false
    }
}, {
    path: '/course/find/detail',
    component: resolve => require(['@/views/Course/Find/detail.vue'], resolve),
    meta: {
        title: "在线学习-发现",
        auth: false
    }
}, {
    path: '/course/find/play',
    component: resolve => require(['@/views/Course/Find/videoPlay.vue'], resolve),
    meta: {
        title: "在线学习-发现",
        auth: false
    }
}, {
    path: '/course/order/detail',
    component: resolve => require(['@/views/Course/Order/detail.vue'], resolve),
    meta: {
        title: "在线学习-订单详情",
        auth: false
    }
}, {
    path: '',
    component: () =>
        import ('@/views/Course/layout'),
    children: [{
        path: '/course/find',
        component: resolve => require(['@/views/Course/Find/index.vue'], resolve),
        meta: {
            title: "在线学习-发现",
            auth: false
        }
    }, {
        path: '/course/buy',
        component: resolve => require(['@/views/Course/Buy/index.vue'], resolve),
        meta: {
            title: "在线学习-已购",
            auth: false
        }
    }, {
        path: '/course/order',
        component: resolve => require(['@/views/Course/Order/index.vue'], resolve),
        meta: {
            title: "在线学习-已购",
            auth: false
        }
    }]
}]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
        // if (!to.matched.some(record => record.meta.auth == false) && !localStorage.getItem('token')) {
        //     next('/login')
        // } else {
        //     next()
        // }
})
export default router