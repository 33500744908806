import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './unit/rem'
import './unit/main'
import http from 'vue-http-rexsheng'
import './router/mock'
import alert from './components/message/alert'
import MessageBox from './components/message/message'
import './assets/css/basic.scss'


Vue.use(alert)
Vue.use(MessageBox);
Vue.config.productionTip = false
Vue.use(http)
http.ajax.config.mockMode = false
Vue.prototype.domainUrl = 'http://oss.hailuoguniang.com/';

http.ajax.interceptors.setResponse(response => {
    if (response.status == 404 || response.status == 500) {
        Vue.prototype.$msgBox.show({
            title: '操作提示',
            content: '请求出错，请稍后再试',
            confirmBtnText: '确定', //确定按钮上面的文字
            isShowCancelBtn: false, //是否显示取消按钮，不写默认显示
            confirm() {}
        })
    } else {
        return response
    }
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')