<template>
<transition name="fade">
    <div class="alert-main" v-show="show">
      <span class="iconfont iconroundcheck success" v-if="type=='success'"></span>
      <span class="iconfont info iconinfo" v-else-if="type=='info'"></span>
      <span class="iconfont warning iconwarn" v-else-if="type=='warning'"></span>
      <span class="iconfont error iconroundclose" v-else-if="type=='error'"></span>
      <span class="text">{{text}}</span>
    </div>
</transition>
</template>

<script type="text/ecmascript-6">
  export default {
    name: 'alert',
    props: {
      type: {
        type: String,
        default: 'info',
        validator: val => ['info', 'success', 'warning', 'error'].includes(val)
      },
      text: {
        type: String,
        default: ''
      },
      show: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
.fade-enter-active {
  animation: fade-in .3s ease-out;
}
.fade-leave-active {
  animation: fade-in .3s reverse ease-out;
}
@keyframes fade-in {
  0% {
    top: -40px;
  }
  100% {
    top: 30%;
  }
}
.alert-main{
    position: absolute;
    margin: 0 auto;
    max-width: 680px;
    line-height: 40px;
    padding: 8px 20px;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    background: #fff;
    border: #eee 1px solid;
    box-shadow: 0 1px 6px rgba(0,0,0,.2);
    border-radius: 6px; 
    z-index: 2121;
    font-size: 30px;
}
.alert-main .iconfont{
  margin-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}
.alert-main .success{
  color: #29d3ce
}
.alert-main .info{
  color: #2d8cf0
}
.alert-main .warning{
  color: #f90
}
.alert-main .error{
  color: #ed4014
}
</style>

