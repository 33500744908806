<template>
    <div class="login">
        <div class="title">账号登录</div>
        <div class="login-form">
            <div class="form-group">
                <input type="number" placeholder="请输入手机号码" v-model="phone" @input="phone!=''?errorText='':errorText='请输入手机号'">
            </div>
            <div class="form-group codeForm">
                <button class="fr" :disabled="codeDis" @click="getCode">{{codeText}}</button>
                <input type="text" placeholder="请输入验证码" v-model="code" @input="code!=''?errorText='':errorText='请输入验证码'">                
            </div>            
            <div class="error">{{errorText}}</div>
            <button class="btn" @click="loginIn">登录</button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            phone: '',
            code: '',
            errorText: '',
            codeDis:false,
            codeText: "获取验证码",
            menuNum: 60,
        }
    },
    mounted() {
    },
    methods: {
        check() {
            if (this.phone == "") {
                this.errorText = "请输入手机号";
                return false;
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
                this.errorText = "请输入正确的手机号码";
                return false;
            } else if (this.code == undefined || this.code == "") {
                this.errorText = "请输入验证码";
                return false;
            } else {
                return true;
            }
        },
        getCode() {
            var self = this;
            if (this.phone == "") {
                this.errorText = "请输入手机号";
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
                this.errorText = "请输入正确的手机号码";
            }else{
                self.codeDis = true;
                    self.codeText = self.menuNum + "s";
                    self.timer = setInterval(function () {
                        if (self.menuNum == 0) {
                            clearInterval(self.timer);
                            self.codeDis = false;
                            self.codeText = "重新发送";
                            self.menuNum = 60;
                        } else {
                            self.menuNum--;
                            self.codeText = self.menuNum + "s";
                        }
                    }, 1000);
                    self.$ajax.send({
                        url: "/api/user/send_code",
                        data: { aunt_card: self.phone },
                        type: 'post',
                        success: function (data) {
                            if (!!data && data.code == "1001") {

                            }
                        }
                    });
            }
            
        },
        loginIn() {
            var self = this;
            if (this.check()) {
                this.$ajax.send({
                    url: "/api/user/login",
                    data: { aunt_card: self.phone, code: self.code },
                    type: "post",
                    success: function (data) {
                        if (!!data && data.code == "1001") {
                            localStorage.setItem('aunt_id', data.data.aunt_id);
                            localStorage.setItem('phone', self.phone);
                            localStorage.setItem('token', data.data.token);
                            self.$router.push({
                                path: "/order",
                                query: { type: 2 }
                            });
                        } else {
                            self.errorText = data.message;
                        }
                    }
                });
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.login {
    padding-top: 240px;
    .title {
        text-align: center;
        font-size: 56px;
        color: #353535;
        margin-bottom: 160px;
    }
    .login-form {
        margin: 10px 32px;
        .form-group{            
            margin-bottom: 20px;
            border-bottom: #f1f2f3 1px solid;
            input {
                border: none;
                outline: none;
                line-height: 30px;
                padding: 24px 0;
                width: 100%;
                font-size: 30px;
                &::placeholder {
                    color: #c5c5c5;
                }
            }         
            &.codeForm{
                input{
                    width: calc(100% - 270px);
                }
                button {
                    width: 230px;
                    margin: 0;
                    height: 86px;
                    background: none;
                    color: #d72b20;
                    border: none;
                    font-size: 30px;
                    text-align: right;
                    &:disabled {
                        cursor: no-drop;
                        color: #959595;
                    }
                }
                
            }
        }
        
            button {
                width: 100%;
                height: 88px;
                background: #d51f13;
                border-radius: 8px;
                color: #fff;
                font-size: 32px;
                border: none;
                margin-top: 80px;
            } 
            
            .error {
                height: 40px;
                font-size: 28px;
                line-height: 40px;
                color: #d51f13;
            } 
    }
}
</style>