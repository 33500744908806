import msgboxVue from './message.vue';    
export default {
    install(Vue, options) {   //插件安装
        let MessageBox  = Vue.extend(msgboxVue)  
        let $vm = new MessageBox ()

        Vue.prototype.$msgBox = { // 在Vue的原型上添加实例方法，以全局调用  //layer 方法
            show(options) { // 控制toast显示的方法  //show 事件
                // $vm.title=opt.title || '操作提示';
                // $vm.content=opt.content;
                // $vm.isShowCancelBtn=opt.isShowCancelBtn || true;
                // $vm.cancelBtnText=opt.cancelBtnText || '取消';
                // $vm.confirmBtnText=opt.confirmBtnText || '确定';
                // $vm.confirm=opt.confirm;
                // $vm.cancle=opt.cancle;
                Object.assign($vm, options);
                // $vm = opt;
                $vm.show=true;
                document.body.appendChild($vm.$mount().$el)   //插入dom
            },
            hide(options) { // 控制toast隐藏的方法                
                setTimeout(function () {
                    $vm.show=false;
                }, 100);
                document.body.removeChild($vm.$mount().$el)
                
            }
        }
}}